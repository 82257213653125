import React, { useState } from "react";
import "../styles/Signup.css";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    contact_number: "",
    address: "",
    password: "",
    password2: "",
  });

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="profile-container">
      <h2>{profile.username}</h2>
      {isEditing ? (
        <div>
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={profile.username}
              onChange={handleChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={profile.email}
              onChange={handleChange}
            />
          </label>
          <label>
            Address:
            <input
              type="text"
              name="address"
              value={profile.address}
              onChange={handleChange}
            />
          </label>
          <label>
            Nickname:
            <input
              type="text"
              name="nickname"
              value={profile.nickname}
              onChange={handleChange}
            />
          </label>
          <label>
            DOB:
            <input
              type="text"
              name="dob"
              value={profile.dob}
              onChange={handleChange}
            />
          </label>
        </div>
      ) : (
        <div>
          <p>Username: {profile.username}</p>
          <p>Email: {profile.email}</p>
          <p>Address: {profile.address}</p>
          <p>Nickname: {profile.nickname}</p>
          <p>DOB: {profile.dob}</p>
        </div>
      )}
      <button onClick={handleEditToggle}>
        {isEditing ? "Save Changes" : "Edit Profile"}
      </button>
    </div>
  );
};

export default Profile;
