import React, {useEffect, useState} from "react";
import "../styles/Mybookings.css";
import {AgGridReact} from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import {toast, ToastContainer} from "react-toastify";

function MyBookings() {
    const [rowData, setRowData] = useState([]);

    const cancelBooking = (bookingId) => {
        const headers = {
            "Content-Type": "application/json",
            "X-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        const token = localStorage.getItem("token");
        if (token) {
            console.log("Token:", token);
            headers["Authorization"] = `Bearer ${token}`;
        }
        fetch("http://192.53.168.204:8000/api/bookings/cancel?booking_id=" + bookingId, {
            headers: headers,
            method: "POST",
        })
            .then((response) => {
                if (!response.ok) {
                    // If the response status is not ok (like 400 for bad request), reject the Promise with the response data
                    return response.json().then((data) => Promise.reject(data));
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                toast("Booking Cancelled Successfully", {
                    type: "success",
                });
                //refresh the page
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error:", error)
                toast(error.message, {
                    type: "error",
                })
            });

    }

    function CancelButton(props) {
        const {value, data} = props;

        const handleClick = () => {
            // show confirmation dialog
            if (window.confirm("Are you sure you want to cancel this booking?"))
                cancelBooking(data.booking_id);
        };

        return (
            data.passed === "Upcoming" &&
            <button className={'cancelButton'} onClick={handleClick}>
                Cancel
            </button>
        );
    }

    // Column Definitions: Defines the columns to be displayed.
    const colDefs = [
        {field: "booking_id", headerName: "Booking ID"},
        {field: "session_date", headerName: "Session Date"},
        {field: "session_duration", headerName: "Session Duration"},
        {field: "session_description", headerName: "Session Type"},
        {field: "session_location", headerName: "Session Location"},
        {field: "location_type", headerName: "Location Type"},
        {field: "session_cost", headerName: "Session Cost"},
        {
            field: "passed",
            headerName: "Session Status",
            cellClassRules: {
                "rag-green": (params) => params.value === "Completed",
                "rag-red": (params) => params.value === "Upcoming",
            },
        },
        {
            field: "action",
            headerName: "Action",
            cellRenderer: CancelButton
        }
    ];

    //fetch my bookings
    useEffect(() => {
        fetch("http://192.53.168.204:8000/api/bookings/", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const formattedData = data.map((booking) => {
                    const sessionDate = new Date(booking.session.session_date);
                    const startTime = sessionDate.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                    });
                    const closeTime = new Date(
                        sessionDate.getTime() +
                        booking.session.session_duration * 60 * 60 * 1000
                    ).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"});

                    const formatDate = (date) => {
                        const options = {year: "numeric", month: "long", day: "numeric"};
                        return date.toLocaleDateString(undefined, options);
                    };

                    const formatTime = (date) => {
                        const options = {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                        };
                        return date.toLocaleTimeString(undefined, options);
                    };
                    return {
                        booking_id: booking.booking_id,
                        booking_status: booking.status === 3 ? "Cancelled" : "Active",
                        session_date: formatDate(sessionDate),
                        session_duration: `${startTime} - ${closeTime}`,
                        session_cost: "$" + booking.session.session_cost,
                        session_location: booking.session.session_location,
                        location_type: booking.session.location_type === 1 ? "Online": "In-Person",
                        session_description: booking.session.session_type,
                        passed: booking.session.passed == true ? "Completed" : "Upcoming",
                        name: booking.name,
                        email: booking.email,
                    };
                });
                setRowData(formattedData);
            })
            .catch((error) => {
                console.error("Error fetching bookings:", error);
            });
    }, []);

    const rowClassRules = {
        // apply red to Ford cars
        "rag-red": (params) => params.data.passed === "Upcoming",
    };

    return (
        <div className="container-book">
            <div className="my-bookings-container">
                <h1 className="my-bookings-heading">My Bookings</h1>
                <div
                    className="ag-theme-quartz" // applying the Data Grid theme
                    style={{
                        height: "80vh",
                        width: "100%", // width of the parent container
                    }}
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        rowClassRules={{"ag-row-custom": true}} // Apply custom class to rows
                        overlayNoRowsTemplate={"No bookings found. " + localStorage.getItem("token") && "Please register now to view your bookings."}
                    />
                </div>
                <ToastContainer/>
            </div>
        </div>
    );
}


export default MyBookings;
