import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Signup.css";
import signupImage from "../images/signupImage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../utils";

const Signup = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    contact_number: "",
    address: "",
    password: "",
    password2: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const history = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("http://192.53.168.204:8000/api/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (!data.errors) {
          toast.success("Registration successful! Redirecting to login...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            history("/login");
          }, 2000); // Redirect after 2 seconds
        } else {
          // errors is a object with key as field name and value as list of error message
          toast.error(
            capitalizeFirstLetter(data.errors[Object.keys(data.errors)[0]][0]),
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error.message || "An error occurred. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.error("Error:", error);
      });
  };
  return (
    <div className="signup-container">
      <div className="left-section">
        <img src={signupImage} alt="Sign up" />
      </div>
      <div className="right-section">
        <h2>Sign Up</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <label>
            Full Name:
            <input
              type="text"
              name="name"
              placeholder="ex: Arju Poudel"
              value={form.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Your Email:
            <input
              type="email"
              name="email"
              placeholder="Example@email.com"
              value={form.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Contact:
            <input
              type="text"
              name="contact_number"
              value={form.contact_number}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Address:
            <input
              type="text"
              name="address"
              value={form.address}
              onChange={handleChange}
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              name="password"
              value={form.password}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Confirm Password:
            <input
              type="password"
              name="password2"
              value={form.password2}
              onChange={handleChange}
              required
            />
          </label>
          <label className="terms">
            <input type="checkbox" required />
            By signing up, you agree to the{" "}
            <a href="/terms">Play Terms of Service</a>
          </label>
          <button type="submit" className="register-btn">
            Register
          </button>
            <p>
                Already have an account? <a href="/login">Login</a>
            </p>

        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Signup;
