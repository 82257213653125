import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "../styles/Navbar.css";
import logoImage from "../images/logoImage.png";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faSignOutAlt, faUser} from "@fortawesome/free-solid-svg-icons";

function Navbar() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const [userName, setUserName] = useState("");

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
    };

    const handleProfileClick = () => {
        navigate("/MyProfile");
    };
    const handleMyBookingsClick = () => {
        navigate("/MyBookings");
    };
    // Close dropdown when clicking outside
    useEffect(() => {

        // fetch user's profile data if token is present
        if (localStorage.getItem("token")) {
            fetch("http://192.53.168.204:8000/api/auth/profile", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        return res.json().then((data) => {
                            throw new Error(data.detail || "Profile fetch failed!");
                        });
                    }
                })
                .then((data) => {
                    setUserName(data.name);
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        // provide a bottom shadow to the navbar to make it look cool
        <div className="navbar" style={{borderBottom: "1px solid #e0e0e0",}}>
            <div className="logo">
                <img src={logoImage} alt="Mrs Oh Wellness"/>
            </div>
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/booking">Make a Booking</Link>
                    </li>
                    <li>
                        <Link to="/about">About Us</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>

                    {localStorage.getItem("token") ? (
                        <>
                            <li>
                                <Link to={"/MyBookings"}>View My Bookings</Link>
                            </li>
                            <li className="dropdown-container" ref={dropdownRef}>
                                <button className="icon-button" onClick={toggleDropdown}>
                                    <FontAwesomeIcon icon={faUser}/>
                                    <FontAwesomeIcon icon={faCaretDown} className="more-icon"/>
                                </button>
                                {dropdownOpen && (
                                    // show user's name as title
                                    // show My Profile and Logout buttons
                                    <>
                                        <div className="dropdown-menu">
                                            <div className="dropdown-title">
                                                {userName}
                                            </div>
                                            <button onClick={handleLogout} className="dropdown-item">
                                                <FontAwesomeIcon icon={faSignOutAlt} className="dropdown-icon"/>
                                                Logout
                                            </button>
                                        </div>
                                    </>
                                )}
                            </li>
                        </>
                    ) : (
                        <>
                            <li>
                                <Link to="/login" className="login-button">
                                    Login
                                </Link>
                            </li>
                            <li>
                                <Link to="/signup" className="login-button">
                                    Register
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </nav>
        </div>
    );
}

export default Navbar;
