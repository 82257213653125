import React, { useState } from "react";
import "../styles/About.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import locationImg from "../images/location-icon.png";
import phoneImg from "../images/phone-icon.png";
import emailImg from "../images/email-icon.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  // Validation function for local front validation
  const validate = () => {
    let formErrors = {};
    if (!formData.name.trim()) formErrors.name = "Name is required";
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email address is invalid";
    }
    if (!formData.phone) {
      formErrors.phone = "Phone number is required";
    } else if (!/^\d{10,15}$/.test(formData.phone)) {
      formErrors.phone = "Phone number must be 10-15 digits";
    }
    if (!formData.message.trim()) formErrors.message = "Message is required";
    return formErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    // If no local validation errors, then doing API call
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch(
          "http://192.53.168.204:8000/api/auth/contact",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (response.ok) {
          // Success to show toast and reset form
          toast.success("Message sent successfully!", {
            position: "top-right",
          });
          setFormData({ name: "", email: "", phone: "", message: "" });
          setErrors({}); // Clear any previous errors
        } else {
          // Server side validation errors
          if (data.errors) {
            setErrors(data.errors); // API validation errors
          } else {
            toast.error("Failed to send the message.", {
              position: "top-right",
            });
          }
        }
      } catch (error) {
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
        });
      }
    } else {
      toast.error("Please fill in all fields correctly", {
        position: "top-right",
      });
    }
  };

  // Handling input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="contact-page">
      <header className="contact-header">
        <div className="contact-info">
          {/* Contact details */}
          <div className="contact-item">
            <div className="contact-box">
              <img src={locationImg} alt="location" />
              <p>24 Wright Street, Ferryden Park, SA, Australia, 5010</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-box">
              <img src={phoneImg} alt="phone" />
              <p>+61 0410122954</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-box">
              <img src={emailImg} alt="email" />
              <p>hello@msohwellness.com.au</p>
            </div>
          </div>
        </div>
      </header>

      <section className="contact-main">
        <div className="contact-container">
          <div className="contact-form">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <span className="error">{errors.name}</span>}

              <label>Email</label>
              <input
                type="text"
                name="email"
                placeholder="Enter a valid email address"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}

              <label>Phone</label>
              <input
                type="tel"
                name="phone"
                placeholder="Enter your number"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <span className="error">{errors.phone}</span>}

              <label>Message</label>
              <textarea
                name="message"
                placeholder="Type your message..."
                value={formData.message}
                onChange={handleChange}
              />
              {errors.message && (
                <span className="error">{errors.message}</span>
              )}

              <button type="submit">Submit</button>
            </form>
          </div>

          {/* Directions Section */}
            <div className="directions">
                <h2>Directions →</h2>
                <p>
                    Open Hours <br/>
                    10 A.M. - 5 P.M. <br/>
                    Monday - Friday <br/>
                    All Sessions By Appointment
                </p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d418339.51540151064!2d138.28152566610058!3d-34.99975887961821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab735c7c526b33f%3A0x4033654628ec640!2sAdelaide%20SA!5e0!3m2!1sen!2sau!4v1726665380084!5m2!1sen!2sau"
                    width="100%"
                    height="300"
                    style={{border: 0}}
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Map"
                ></iframe>
            </div>
        </div>
      </section>
        <ToastContainer/>
    </div>
  );
};

export default Contact;
