import React, { useState } from "react";
import "../styles/Footer.css";
import Logo2 from "../images/logo2.png";
import {Link} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Footer() {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateEmail(email)) {
      // Send email to your API using fetch

      fetch("http://192.53.168.204:8000/api/auth/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Email submitted:", email);
            setEmail("");
            toast.success("Subscribed successfully");
          } else {
            throw new Error("Failed to send email");
          }
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    } else {
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <footer className="footer-container">
      <div className="newsletter">
        <h2>Join Our Community</h2>
        <p>
          Enter your email address to register to our newsletter subscription
          delivered on regular basis!
        </p>
        <div className="subscribe">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button onClick={handleSubmit}>Subscribe</button>
        </div>
      </div>

      <div className="footer-content">
        <div className="footer-section logo-section">
          <img src={Logo2} alt="Mrs. Oh Wellness" className="footer-logo" />{" "}
          {/* Replace with actual logo path */}
          <h3>Mrs. Oh Wellness Coaching</h3>
          <p>
            Empowering wellness: Bridging Health Gaps through Coaching and
            Support
          </p>
        </div>

        <div className="footer-section quick-links">
          <h4>Quick Links</h4>
          <ul>
            <li>
              <Link to={"/booking"} >Make a booking</Link>
            </li>
            <li>
              <Link to={"/about"} >About Us</Link>
            </li>
            <li>
                <Link to={"/contact"} >Contact</Link>
            </li>
          </ul>
        </div>

        <div className="footer-section contact-details">
          <h4>Contact Details</h4>
          <p>
            <strong>Address:</strong> 1, Test Street, Australia
          </p>
          <p>
            <strong>Phone:</strong> +61 2 8006 9055
          </p>
          <p>
            <strong>Email:</strong> info@mrsohwellnesscoaching.com.au
          </p>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-left">
          <p>&copy; Mrs. Oh Wellness</p>
        </div>
        <div className="footer-right">
          <div className="footer-links">
            <a href="#">Privacy Policy</a> |{" "}
            <a href="#">Cancellation and Change of Date Policy</a>
          </div>
          <p className="design-credit">
            Website Design and Development using WordPress
          </p>
        </div>
      </div>
        <ToastContainer/>
    </footer>
  );
}

export default Footer;
