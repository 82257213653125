import React, { useState } from "react";
import {toast, ToastContainer} from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

    const handleForgotPasswordClick = (e) => {
        e.preventDefault();

        fetch('http://192.53.168.204:8000/api/auth/reset-password?email=' + email, {
            method: 'POST',
        })
        .then(response => {
            const data = response.json();
            if (response.status === 200) {
                // Show success message
                toast.success(
                    "Password reset link has been sent to your email! Redirecting to login...",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
            } else {
                // Show error message
                console.error('Error:', data.message);
                toast.error(
                    "Error: " + data.message,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Forgot Password</h1>
      <form onSubmit={handleForgotPasswordClick} style={styles.form}>
        <label style={styles.label}>
          Email:
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            style={styles.input}
          />
        </label>
        <button type="submit" style={styles.button}>
          Reset Password
        </button>
        <p>Back to <a href="/login">Login</a></p>
      </form>
    <ToastContainer/>
    </div>
  );
};

// Inline styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    padding: "20px",
  },
  heading: {
    fontSize: "2rem",
    marginBottom: "20px",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  label: {
    fontSize: "1.1rem",
    marginBottom: "10px",
    width: "100%",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginTop: "5px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "1rem",
  },
  button: {
    backgroundColor: "#4CAF50",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    fontSize: "1.1rem",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
};

// Exporting component
export default ForgotPassword;
