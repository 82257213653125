import React, {useEffect, useState} from "react";
import "../styles/Booking.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {toast, ToastContainer} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Booking() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [workshopType, setWorkshopType] = useState(2);
    const [selectedDate, setSelectedDate] = useState("");
    const [agreedToAlerts, setAgreedToAlerts] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [availableSessions, setAvailableSessions] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [termsError, setTermsError] = useState("");
    const [myBookings, setMyBookings] = useState([]);
    const [sessionsError, setSessionsError] = useState("");
    const [upcomingSessions, setUpcomingSessions] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);

    const [message, setMessage] = useState("");
    //call profile API when loading booking page

    const navigate = useNavigate();
    const makeSelected = (index) => {
        setSelectedSessionId(index);
    };

    const initiatePayment = (e, booking_id, session_price) => {
        e.preventDefault();

        if (selectedSessionId === null) {
            // Show error message below the session list
            setSessionsError("Please select a session to reserve");
            return;
        } else {
            setSessionsError("");
        }
        if (!agreedToTerms) {
            // Show error message below the checkbox
            setTermsError("Please agree to the terms and conditions");
            return;
        } else {
            setTermsError("");
        }
        const headers = {
            "Content-Type": "application/json",
            "X-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        const token = localStorage.getItem("token");
        if (token) {
            console.log("Token:", token);
            headers["Authorization"] = `Bearer ${token}`;
        }
        const body = {
            selectedSessionId: selectedSessionId,
            name: name,
            email: email,
            selectedDate: selectedDate,
            workshopType: workshopType,
            agreedToTerms: agreedToTerms,
        }
        fetch("http://192.53.168.204:8000/api/payment?booking_id=" + booking_id + "&session_price=" + session_price, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // redirect to data.url
                window.location.href = data.url;
            })
            .catch((error) => {
                console.error("Error:", error);
                alert("Failed to fetch profile, please try again.");
            });
    }

    const confirmBooking = (bookingId) => {
        const headers = {
            "Content-Type": "application/json",
            "X-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        const token = localStorage.getItem("token");
        if (token) {
            console.log("Token:", token);
            headers["Authorization"] = `Bearer ${token}`;
        }
        fetch("http://192.53.168.204:8000/api/bookings/confirm?booking_id=" + bookingId, {
            headers: headers,
            method: "POST",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // redirect to data.url
                navigate("/MyBookings");
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const cancelBooking = (bookingId) => {
        const headers = {
            "Content-Type": "application/json",
            "X-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        const token = localStorage.getItem("token");
        if (token) {
            console.log("Token:", token);
            headers["Authorization"] = `Bearer ${token}`;
        }
        fetch("http://192.53.168.204:8000/api/bookings/cancel?booking_id=" + bookingId, {
            headers: headers,
            method: "POST",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // redirect to data.url
                navigate("/MyBookings");
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            toast.success(
                "Reservation successful! Redirecting to your bookings...",
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                }
            );
            confirmBooking(query.get("booking_id"));
        }

        if (query.get("canceled")) {
            toast("Order canceled.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            cancelBooking(query.get("booking_id"));
        }
        fetchAllSessions();
        fetchAvailableDates();
        // set the value of selected date to today's date

        const token = localStorage.getItem("token");
        if (token) {
            setIsLoggedIn(true);
            const headers = {
                "Content-Type": "application/json",
            }
            if (token) {
                console.log("Token:", token);
                headers["Authorization"] = `Bearer ${token}`;
            }
            fetch("http://192.53.168.204:8000/api/auth/profile", {
                headers: headers,
            })
                .then((response) => response.json())
                .then((data) => {
                    setName(data.name);
                    setEmail(data.email);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    alert("Failed to fetch profile, please try again.");
                });
        }
    }, []);

    //fucntion to call the api to list available sessions
    const fetchAvailableSessions = (date, workshopType) => {
        fetch("http://192.53.168.204:8000/api/sessions/?session_date=" + date + "&location_type=" + workshopType, {
            headers: {
                "Content-Type": "application/json",
                "X-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                const availableSessions = data.map((session) => {
                    const sessionDate = new Date(session.session_date);
                    const date = sessionDate
                        .toLocaleDateString([], {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })
                        .replace(/^\w+,\s/, ""); // Remove the day part

                    const startTime = sessionDate.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                    });
                    const closeTime = new Date(
                        sessionDate.getTime() + session.session_duration * 60 * 60 * 1000
                    ).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"});

                    return {
                        site: session.location_type === 1 ? "ONLINE" : "ONSITE",
                        session_type: session.session_type,
                        date: date,
                        location: session.session_location,
                        startTime: startTime,
                        closeTime: closeTime,
                        session_id: session.session_id,
                        passed: session.passed,
                    };
                });
                setAvailableSessions(availableSessions);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    //fucntion to call the api to list available sessions
    const fetchAllSessions = () => {
        fetch("http://192.53.168.204:8000/api/sessions/?limit=4&ignore_passed=true", {
            headers: {
                "Content-Type": "application/json",
                "X-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const availableSessions = data.map((session) => {
                    const sessionDate = new Date(session.session_date);
                    const date = sessionDate
                        .toLocaleDateString([], {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })
                        .replace(/^\w+,\s/, ""); // Remove the day part

                    const startTime = sessionDate.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                    });
                    const closeTime = new Date(
                        sessionDate.getTime() + session.session_duration * 60 * 60 * 1000
                    ).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"});

                    return {
                        site: session.location_type === 1 ? "ONLINE" : "ONSITE",
                        session_type: session.session_type,
                        date: date,
                        location: session.session_location,
                        startTime: startTime,
                        closeTime: closeTime,
                        session_id: session.session_id,
                        passed: session.passed,
                    };
                });
                setUpcomingSessions(availableSessions);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const fetchAvailableDates = () => {
        fetch("http://192.53.168.204:8000/api/sessions/available_dates/")
            .then((response) => response.json())
            .then((data) => {
                setAvailableDates(data);
                setSelectedDate(data[0]);
                fetchAvailableSessions(data[0], 2);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    // Get today's date in YYYY-MM-DD format
    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const dd = String(today.getDate()).padStart(2, "0");
        return `${yyyy}-${mm}-${dd}`;
    };

    //fetch my bookings
    useEffect(() => {

        if (localStorage.getItem("token") !== null) {
            fetch("http://192.53.168.204:8000/api/bookings/", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("Success:", data);
                    //   [{
                    //     "booking_id": 1,
                    //     "booked_date": "2024-09-01T01:31:52.954629+09:30",
                    //     "status": 2,
                    //     "session": {
                    //         "session_id": 45,
                    //         "coach_user": {
                    //             "name": "Mrs. Oh",
                    //             "email": "admin@mrsohwellnesscoaching.com.au"
                    //         },
                    //         "session_type": 1,
                    //         "session_date": "2024-09-01T12:30:00+09:30",
                    //         "session_duration": 2.0,
                    //         "session_location": "Adelaide",
                    //         "session_description": "Physical Training",
                    //         "location_type": 1,
                    //         "max_participant": 29
                    //     },
                    //     "name": "Mrs. Oh",
                    //     "email": "admin@mrsohwellnesscoaching.com.au"
                    // },]
                    setMyBookings(data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    alert("Failed to fetch bookings, please try again.");
                });
        }
    }, []);

    const handleReserve = (e) => {
        e.preventDefault();
        if (selectedSessionId === null) {
            // Show error message below the session list
            setSessionsError("Please select a session to reserve");
            return;
        } else {
            setSessionsError("");
        }
        if (!agreedToTerms) {
            // Show error message below the checkbox
            setTermsError("Please agree to the terms and conditions");
            return;
        } else {
            setTermsError("");
        }
        // Reset the error message
        setTermsError("");
        console.log(availableSessions[selectedSessionId]);
        const reservationData = {
            session_id: availableSessions[selectedSessionId].session_id,
            name: name,
            email: email,
        };
        fetch("http://192.53.168.204:8000/api/bookings/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...(localStorage.getItem("token") && {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }),
            },
            body: JSON.stringify(reservationData),
        })
            .then((response) => {
                if (response.status === 201) {
                    response.json().then((data) => {
                        initiatePayment(e, data.booking_id, data.session.session_price_id);
                    });
                } else {
                    return response.json().then((data) => {
                        throw new Error(data.error);
                    });
                }
            })
            .catch((error) => {
                toast.error(error.message || "An error occurred. Please try again.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });
    };

    // const availableSessions = [
    //   {
    //     site: "Adelaide Site A",
    //     date: "16 March, 2024",
    //     location: "12 Test St, Adelaide 5012",
    //     startTime: "8:00 AM",
    //     closeTime: "6:00 PM",
    //   },
    // ];

    return (
        <div className="booking-container">
            <ToastContainer/>
            <form className="booking-form" onSubmit={(e) => handleReserve(e)}>
                <h2>Make reservation for a Workshop Session</h2>

                <label>
                    Your Name
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={isLoggedIn}
                        placeholder={!isLoggedIn ? "Enter your name" : ""}
                    />
                </label>

                <label>
                    Email
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isLoggedIn}
                        placeholder={!isLoggedIn ? "Enter your email" : ""}
                    />
                </label>

                <label>
                    Preferred Workshop Type
                    <div className="workshop-type">
                        <label>
                            <input
                                type="radio"
                                value="online"
                                checked={workshopType === 1}
                                onChange={() => {
                                    setWorkshopType(1);
                                    fetchAvailableSessions(selectedDate, 1);
                                }}
                            />
                            Online (Zoom Call or Google Meet)
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="inPerson"
                                checked={workshopType === 2}
                                onChange={() => {
                                    setWorkshopType(2);
                                    fetchAvailableSessions(selectedDate, 2);
                                }}
                            />
                            In Person (See available workshop sites on the right pane)
                        </label>
                    </div>
                </label>

                <label>
                    Choose a Session
                    {sessionsError && <p className="error-message">{sessionsError}</p>}
                </label>
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                        setSelectedDate(date.toISOString().split('T')[0]);
                        fetchAvailableSessions(date.toISOString().split('T')[0], workshopType);
                    }}
                    dateFormat="yyyy-MM-dd"

                    filterDate={(date) => {
                        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                        return availableDates.includes(localDate.toISOString().split('T')[0]);
                    }}/>

                <div className="available-session-list">
                    {availableSessions.filter(
                        (session) => session.passed === false
                    ).length === 0 && (
                        <p>No sessions available for the selected date</p>
                    )}
                    {availableSessions.filter(
                        (session) => session.passed === false
                    ).map((session, index) => (
                        <div
                            className={`session-card ${
                                selectedSessionId === index ? "selected" : ""
                            }`}
                            key={index}
                            data-session-id={index}
                            onClick={() => makeSelected(index)}
                        >
                            <h3>{session.session_type}</h3>
                            <p>Event Date: {session.date}</p>
                            <p>Location: {session.location}</p>
                            <p>Start Time: {session.startTime}</p>
                            <p>Close Time: {session.closeTime}</p>
                        </div>
                    ))}
                </div>
                <br/>
                <label>
                    <input
                        type="checkbox"
                        checked={agreedToTerms}
                        onChange={() => setAgreedToTerms(!agreedToTerms)}
                        required
                    />
                    I agree to the <a href="#">terms and conditions</a>.
                </label>
                {termsError && <p className="error-message">{termsError}</p>}
                <center>
                    <button className={'reserveButton'} type={'submit'}>
                        Reserve
                    </button>
                </center>
            </form>
            <div className="workshops-list">
                {localStorage.getItem("token") && (
                    myBookings.length === 0 ? (
                        <div className="popup-empty-box">
                            <div className="popup-content">
                                <p>You have no upcoming bookings. Book a session now!</p>
                            </div>
                        </div>
                    ) : (
                        <div className="popup-box">
                            <div className="urgent-sign"></div>
                            <div className="popup-content">
                                <p>
                                    {/* urgent icon */}
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        className="urgent-icon"
                                    />
                                    <b> You have an upcoming booking:</b>
                                    <br/>
                                    <br/>
                                    <span>
                  {new Date(
                      myBookings[0].session.session_date
                  ).toLocaleDateString([], {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                  })}
                                        , {myBookings[0].session.session_location}
                </span>
                                </p>
                                <Link to="/MyBookings">View my bookings</Link>
                                {/* <a href="">View my bookings</a> */}
                            </div>
                        </div>
                    ))}
                <h2>Available Sessions</h2>
                <div className="workshops-card-list">
                    {upcomingSessions.map((workshop, index) => (
                        <div className="workshop-card" key={index}>
                            <h3>{workshop.session_type}</h3>
                            <p>Event Date: {workshop.date}</p>
                            <p>Location: {workshop.location}</p>
                            <p>Start Time: {workshop.startTime}</p>
                            <p>Close Time: {workshop.closeTime}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Booking;
