import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Home.css";
import gutImage from "../images/home1.png";
import gutHealth from "../images/guthealth.png";
import agingImage from "../images/agingwell.png";
import mentalImage from "../images/mentalhealth.png";

function Home() {
  const navigate = useNavigate(); // Initialize navigate

  // Define the onClick handler
  const handleBookingClick = () => {
    navigate("/booking"); // Navigate to the Booking page
  };

  return (
    <div className="home-container">
      <div className="hero-section">
        <div className="hero-text">
          <h1>
            Embark on Your <span>Journey to Wellness</span> Today
          </h1>
          <p>
            Discover the path to a better you with our personalized wellness
            coaching. Let our dedicated coaches guide you towards a happier,
            healthier lifestyle. Book your session now and start your journey to
            wellness!
          </p>
          <button onClick={handleBookingClick} className="cta-button">
            Book a Session Now <span class="arrow"></span>
          </button>
        </div>
        <div className="hero-image">
          <img src={gutImage} alt="Gut Health Benefits" />
        </div>
      </div>

      <div className="Second-Page">
        <h1 className="title">Our Services</h1>
        {/* <p className="subtitle">
          Discover the Benefits of Partnering with Our Wellness Coaching Program
        </p> */}
        <div className="purpose-item">
          <div className="purpose-image left-image">
            <img src={gutHealth} alt="Gut Health" /> {/* Add image source */}{" "}
          </div>{" "}
          <div className="purpose-text right-text">
            <h3> Gut Health </h3>{" "}
            <p>
              {" "}
              Maintaining gut balance is crucial for overall well - being.A
              healthy gut supports efficient digestion and nutrient absorption,
              ensuring your body gets essential vitamins and minerals.It also
              plays a key role in immune function, as it houses many of the body
              's immune cells and acts as a barrier against harmful pathogens.
              Gut health is linked to mental well - being as well.The gut -
              brain axis connects the gut to the brain, influencing mood and
              cognitive functions.Imbalances in gut bacteria can affect mental
              health.{" "}
            </p>{" "}
          </div>{" "}
        </div>{" "}
        <div className="purpose-item">
          <div className="purpose-text left-text">
            <h3> Aging Wellness </h3>{" "}
            <p>
              Aging wellness is about maintaining health and vibrancy as you
              grow older, focusing on physical, mental, and social well -
              being.Physically, staying active through regular exercise and a
              balanced diet helps keep muscles strong and supports
              cardiovascular health.Mentally, engaging in stimulating activities
              and managing stress are crucial for cognitive function and
              emotional balance.Socially, staying connected with friends,
              participating in community activities, and building relationships
              can prevent loneliness and add a sense of purpose.By nurturing
              these aspects, you can enjoy a fulfilling and active life well
              into older age.{" "}
            </p>{" "}
          </div>{" "}
          <div className="purpose-image right-image">
            <img src={agingImage} alt="Aging Wellness" />{" "}
            {/* Add image source */}{" "}
          </div>{" "}
        </div>{" "}
        <div className="purpose-item">
          <div className="purpose-image left-image">
            <img src={mentalImage} alt="Mental Health" />{" "}
            {/* Add image source */}{" "}
          </div>{" "}
          <div className="purpose-text right-text">
            <h3> Mental Health </h3>{" "}
            <p>
              {" "}
              Mental health is crucial for overall well - being, impacting how
              we think, feel, and behave.Prioritizing mental well - being is
              vital for building resilience and managing stress effectively.Good
              mental health supports emotional stability, positive
              relationships, and a balanced outlook on life.Engaging in
              practices like mindfulness, seeking professional help when needed,
              and maintaining a supportive social network can enhance mental
              wellness.By focusing on mental health, individuals can lead more
              fulfilling lives, navigate challenges with greater ease, and
              achieve a balanced and satisfying lifestyle.{" "}
            </p>{" "}
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default Home;
