import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/About.css";
import "../styles/Home.css";
import clientImage from "../images/client.png"; // Import the image
import coachImage from "../images/coachImage.png"; // Import the image
import Icon1 from "../images/Icon1.png";
import Icon2 from "../images/Icon2.png";
import Icon3 from "../images/Icon3.png";
import Icon4 from "../images/Icon4.png";
import Home2 from "../images/home2.png";

function About() {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();

    // Check if email is empty
    if (email === "") {
      toast.error("Please enter your email");
    }
    // Check for valid email format using regex pattern matching for email
    else if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address");
    }
    // If both checks pass
    else {
      toast.success("Thank You for Subscribing !!");
      setEmail(""); // Reseting the email
    }
  };

  return (
    <div className="App">
      <section className="testimonial">
        <div className="testimonial-image">
          <img src={clientImage} alt="My Image" /> {}{" "}
        </div>{" "}
        <div className="testimonial-quote">
          <blockquote>
            {" "}
            “ A great professional coach and excellent consultations service to
            improve your overall holistic being.”{" "}
          </blockquote>{" "}
          <div className="overline">
            {" "}
            <p> Mrs Oh Client </p>{" "}
          </div>{" "}
        </div>{" "}
      </section>

      <section className="discovery">
        <p> Book a free 30 minute discovery session. </p>{" "}
      </section>

      <section className="coach">
        <h2> Meet Your Coach - Seon Oh </h2>{" "}
        <p>
          {" "}
          A certified medical professional at your service helping you improve
          your health.{" "}
        </p>{" "}
        <div className="coach-image">
          <img src={coachImage} alt="Sean oh" /> {/* Add image source */}{" "}
        </div>{" "}
      </section>

      <div className="Second-Page">
        <h1 className="title">Why Choose Us</h1>
        <p className="subtitle">
          Discover the Benefits of Partnering with Our Wellness Coaching Program
        </p>
      </div>
      <div className="grid-container">
        <div className="column1">
          <div className="row1">
            <img src={Icon1} alt="Expertise and Credibility" className="icon" />
            <h2>Expertise and Credibility</h2>
            <p>
              Benefit from Mrs. Oh's background as a trained health
              practitioner, ensuring professional consultations and credible
              guidance.
            </p>
          </div>
          <div className="row2">
            <img src={Icon3} alt="Inclusive Approach" className="icon" />
            <h2>Inclusive Approach</h2>
            <p>
              Engage with a bilingual website and diverse online workshops,
              promoting inclusivity and accessibility for all individuals.
            </p>
          </div>
        </div>

        <div className="column2">
          <img src={Home2} alt="Wellness Coaching" className="main-image" />
        </div>

        <div className="column3">
          <div className="row1">
            <img src={Icon2} alt="Tailored Support" className="icon" />
            <h2>Tailored Support</h2>
            <p>
              Experience personalized coaching tailored to your unique needs and
              goals, fostering holistic well-being and sustainable lifestyle
              changes.
            </p>
          </div>
          <div className="row2">
            <img
              src={Icon4}
              alt="Transparent and Efficient Service"
              className="icon"
            />
            <h2>Transparent and Efficient Service</h2>
            <p>
              Access quick online workshop bookings and secure payment
              transactions, ensuring a seamless and hassle-free experience for
              our clients.
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default About;
