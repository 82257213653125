import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./components/Home";
import Booking from "./components/Booking";
import MyBookings from "./components/Mybookings";
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Profile from "./components/Profile";
import ForgotPassword from "./components/ForgotPassword";
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/booking"
          element={[<Navbar />, <Booking />, <Footer />]}
        />
        <Route path="/about" element={[<Navbar />, <About />, <Footer />]} />
        <Route
          path="/contact"
          element={[<Navbar />, <Contact />, <Footer />]}
        />
        {/* <Route path="/home" element={[<Navbar />, <Home />, <Footer />]} /> */}
        <Route path="/" element={[<Navbar />, <Home />, <Footer />]} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/MyProfile" element={[<Navbar />, <Profile />]} />
        <Route path="/MyBookings" element={[<Navbar />, <MyBookings />]} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    </Router>
  );
}

export default App;
